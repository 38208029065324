<template>
    <div class="pr30">
        <div class="ls_flex_between ls_aic mt20">
            <el-radio-group
                v-model="activeName"
                fill="#31A458"
                @change="
                    params.pageIndex = 1;
                    getList();
                "
            >
                <el-radio-button label="all">系统内所有作品</el-radio-button>
                <el-radio-button label="isShow">首页展示作品</el-radio-button>
                <el-radio-button label="template">模板作品</el-radio-button>
            </el-radio-group>
        </div>

        <div class="ls_flex ls_aic mt20">
            <el-input
                class="w300"
                v-model="params.keyword"
                placeholder="搜索花名称、作品名称"
            ></el-input>
            <el-button
                type="primary"
                @click="
                    params.pageIndex = 1;
                    getList();
                "
            >
                搜索
            </el-button>
        </div>

        <el-radio-group
            v-model="hiddenType"
            fill="#31A458"
            @change="getList"
            class="mt20"
        >
            <el-radio-button :label="0">全部</el-radio-button>
            <el-radio-button :label="2">
                隐藏已设置为首页展示的作品
            </el-radio-button>
            <el-radio-button :label="1">隐藏设为了模板的作品</el-radio-button>
        </el-radio-group>

        <!-- 列表 -->
        <div class="mt30 ls_flex ls_flex_wrap">
            <div
                class="wp19 b-solid br8 box-bb ls_p_rel ls_ovh ls_flex_sk mb14 hover-item"
                v-for="(item, index) in list"
                :key="index"
            >
                <img
                    class="wp100 p12 box-bb"
                    :src="item.coverImage | imgFormat"
                />

                <div class="fz14 lh22 h22 mb10 tac">{{ item.name }}</div>
                <div class="fz14 lh22 h22 mb10 tac">{{ item.userName }}</div>
                <div class="fz14 lh22 h22 mb10 tac">
                    {{ item.institutionName }}
                </div>

                <div
                    class="c3 fz14 h37 lh37 tac bt-solid ls_flex_between ls_aic plr30"
                >
                    <div class="ls_cp" @click="changeShowIndex(item)">
                        {{ item.indexShow ? "取消展示" : "首页显示" }}
                    </div>

                    <div class="ls_cp" @click="changeTemplate(item)">
                        {{ item.template ? "取消模板" : "设为模板" }}
                    </div>
                </div>

                <!-- 浮动悬浮按钮区域 -->
                <div
                    class="ls_p_abs l0 t0 h38 wp100 ls_flex_around ls_aic box-bb bgc08 hover-box"
                >
                    <div
                        class="cf ls_cp pr30 ls_flex_center"
                        @click="viewMaterials(item)"
                    >
                        <i class="iconfont icon-tiezi mr6" />
                        <span class="fz14">用料</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="ls_flex_center mt30">
            <el-pagination
                :current-page="params.pageIndex"
                :page-sizes="[10, 15, 20, 25]"
                :page-size="params.pageSize"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    data() {
        return {
            hideIndexShow: false,
            activeName: "all",

            hiddenType: 0,
            list: [],
            params: {
                keyword: "",
                pageIndex: 1,
                pageSize: 10,
            },
            total: 0,
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            const params = JSON.parse(JSON.stringify(this.params));

            if (this.activeName == "all") {
                params["hiddenType"] = this.hiddenType;
                this.$ajax
                    .post("/admin/product/expand/queryAllProduct", params)
                    .then(res => {
                        this.list = res.list;
                        this.total = res.total;
                    });
            } else {
                params["type"] = this.activeName == "isShow" ? 2 : 1;

                this.$ajax
                    .post("/admin/product/expand/getList", params)
                    .then(res => {
                        this.list = res.list || [];
                        this.total = res.total;
                    });
            }
        },

        // 查看用料
        viewMaterials(item) {
            let dom = "";
            if (!item.useMaterials) {
                this.$message.warning("暂无用料信息");
                return;
            }

            item.useMaterials.map(item => {
                dom += `<div class="ls_flex ls_aic">
                    <span class="lh26 fz14 cf21 mr10">⬤</span>
                    <span class="lh26 fz14">
                        ${item.materialName} x${item.count}
                    </span>
                </div>`;
            });

            this.$confirm(`<div>${dom}</div>`, "用料详情", {
                showCancelButton: false,
                showConfirmButton: false,
                dangerouslyUseHTMLString: true,
                width: "400px",
            });
        },
        // 首页展示/取消展示
        changeShowIndex(item) {
            this.$confirm(
                `确认将此作品${item.indexShow ? "取消" : ""}展示首页吗？`,
                "温馨提示"
            ).then(() => {
                if (item.indexShow) {
                    this.$ajax
                        .post("/admin/product/expand/delete", {
                            productExpandId: item.templateId,
                        })
                        .then(res => {
                            res.data
                                ? this.$message.success("已成功取消展示首页")
                                : this.$message.warning("操作失败");

                            setTimeout(this.getList, 1000);
                        });
                } else {
                    this.$ajax
                        .post("/admin/product/expand/save", {
                            type: 2,
                            productId: item.productId,
                        })
                        .then(res => {
                            res.data
                                ? this.$message.success("已成功设置展示首页")
                                : this.$message.warning("操作失败");

                            this.getList();
                        });
                }
            });
        },
        changeTemplate(item) {
            this.$confirm(
                item.template ? "取消模板" : `设置为模板？`,
                "温馨提示"
            ).then(() => {
                if (item.template) {
                    this.$ajax
                        .post("/admin/product/expand/delete", {
                            productExpandId: item.id,
                        })
                        .then(res => {
                            setTimeout(this.getList, 1000);
                        });
                } else {
                    this.$ajax
                        .post("/admin/product/expand/save", {
                            type: 1,
                            productId: item.productId,
                        })
                        .then(res => {
                            setTimeout(this.getList, 1000);
                        });
                }
            });
        },
        handleSizeChange(val) {
            this.params.pageIndex = 1;
            this.params.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.params.pageIndex = val;
            this.getList();
        },
    },
};
</script>
